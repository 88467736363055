<template>
  <div>
    <b-table
      class="position-relative"
      :items="payments"
      responsive
      :fields="columns"
      primary-key="id"
      show-empty
      empty-text="No se han agregado pagos"
    >

      <template #cell(date)="data">
        <p class="m-0">
          {{ data.item.date }}
        </p>
      </template>

      <template #cell(amount)="data">
        <p class="m-0">
          ${{ numberFormat(data.item.amount) }}
        </p>
      </template>

      <template #cell(payment_method)="data">
        <p class="m-0">
          {{ getPaymentMethodName(data.item.payment_method_id) }}
        </p>
      </template>

      <template #cell(user)="data">
        <div v-if="data.item.user">
          <user-info-component
            :value="data.item.user"
            :model="data.item.user"
          />
        </div>

      </template>

      <template #cell(actions)="data">
        <b-overlay :show="loading">
          <div class="btn-group">
            <button
              class="btn btn-primary"
              :disabled="loading || data.item.reservation_payment_additional_values.length === 0"
              @click="viewAdditionalValues(data.item)"
            >

              <FeatherIcon icon="EyeIcon" />
              Ver valores adicionales

            </button>

            <button
              class="btn btn-info"
              :disabled="loading"
              @click="sendPaymentMail(data.item.id)"
            >

              <FeatherIcon icon="MailIcon" />
              Reenviar correo de pago

            </button>
            <button
              class="btn btn-danger"
              :disabled="loading"
              @click="deletePayment(data.item.id)"
            >

              <FeatherIcon icon="TrashIcon" />
              Eliminar pago

            </button>
          </div>
        </b-overlay>

      </template>

    </b-table>

    <payment-additional-values-modal
      v-if="selectedPayment"
      ref="paymentAdditionalValuesModal"
      :payment="selectedPayment"
    />

  </div>
</template>

<script>
import { BTable, BOverlay } from 'bootstrap-vue'
import UserInfoComponent from '@/views/components/UserInfoComponent.vue'
import GeneralService from '@/dc-it/services/GeneralService'
import PaymentAdditionalValuesModal from '@/views/components/modals/PaymentAdditionalValuesModal.vue'

export default {
  name: 'ReservationPaymentsListTableComponent',
  components: {
    PaymentAdditionalValuesModal,
    BTable,
    UserInfoComponent,
    BOverlay,
  },
  props: {
    payments: {
      type: Array,
      required: true,
    },
    paymentMethods: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    columns: [
      { key: 'date', label: 'Fecha' },
      { key: 'amount', label: 'Monto' },
      { key: 'payment_method', label: 'Método de pago' },
      { key: 'user', label: 'Agente' },
      { key: 'actions', label: 'Acciones' },
    ],
    loading: false,
    selectedPayment: null,
  }),
  methods: {
    numberFormat(number) {
      const tmp = parseFloat(number)
      const val = (tmp).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    getPaymentMethodName(paymentMethodId) {
      if (!paymentMethodId) {
        return 'Monedero de cliente'
      }
      const pm = this.paymentMethods.find(paymentMethod => paymentMethod.getAttr('id').toString() === paymentMethodId.toString())

      return pm ? pm.getAttr('name') : 'No definido'
    },
    sendPaymentMail(id) {
      this.loading = true
      GeneralService.getInstance().save(`reservation_payments/send_mail/${id}`)
        .then(response => {
          if (response.result === 'success') {
            this.$toast.success('Correo enviado correctamente')
          } else {
            this.$toast.error('Error al enviar el correo')
          }
          this.loading = false
        })
    },
    viewAdditionalValues(item) {
      this.selectedPayment = item
      this.$nextTick(() => {
        this.$refs.paymentAdditionalValuesModal.show()
      })
    },
    deletePayment(id) {
      this.loading = true
      GeneralService.getInstance().delete('reservation_payments/delete_payment', id)
        .then(response => {
          if (response.result === 'success') {
            this.$toast.success('Pago eliminado correctamente')
            this.$emit('payment-deleted', id)
          } else {
            this.$toast.error('Error al borrar el pago')
          }
          this.loading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
