<template>
  <b-modal
    ref="paymentAdditionalValuesModal"
    title="Valores adicionales"
    size="lg"
    hide-footer
  >
    <div
      class="row"
    >
      <div
        v-for="value in payment.reservation_payment_additional_values"
        :key="value.id"
        class="col-12"
      >
        <div class="form-group">
          <label>{{ value.payment_method_additional_field.name }}</label>
          <div v-if="value.payment_method_additional_field.type.toString()==='1'">
            <input
              type="text"
              class="form-control"
              disabled
              :value="value.value"
            >
          </div>
          <div v-else-if="value.payment_method_additional_field.type.toString()==='2'">
            <multimedia-viewer-component :multimedia-id="value.value" />
          </div>
        </div>
      </div>
    </div>

  </b-modal>
</template>

<script>
export default {
  name: 'PaymentAdditionalValuesModal',
  components: {
    MultimediaViewerComponent: () => import('@/views/components/MultimediaViewerComponent.vue'),
  },

  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    show() {
      this.$refs.paymentAdditionalValuesModal.show()
    },
  },
}
</script>

<style scoped>

</style>
